<div class="wrapper">
  <div class="novaccess-logo">
    <svg class="shadow logo" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 146.85 147.86">
      <defs>
        <style>.cls-1 {
          fill: #fff;
        }</style>
      </defs>
      <rect class="cls-1" x="66.4" y="23.66" width="14.8" height="14.8"/>
      <rect class="cls-1" x="86.25" y="1.1" width="9.74" height="9.74" transform="translate(61.78 92.44) rotate(-75)"/>
      <rect class="cls-1" x="62.67" y="46.85" width="11.85" height="11.85" transform="translate(-11.32 19.55) rotate(-15)"/>
      <rect class="cls-1" x="103.51" y="45.17" width="14.8" height="14.8" transform="translate(-11.43 62.5) rotate(-30)"/>
      <rect class="cls-1" x="136.01" y="50.47" width="9.74" height="9.74" transform="translate(-9.52 38.34) rotate(-15)"/>
      <rect class="cls-1" x="83.7" y="53" width="11.85" height="11.85" transform="translate(-15.41 80.64) rotate(-45)"/>
      <rect class="cls-1" x="103.51" y="88.02" width="14.8" height="14.8" transform="translate(-27.19 143.75) rotate(-60)"/>
      <rect class="cls-1" x="118.78" y="117.97" width="9.74" height="9.74"
            transform="translate(-50.65 123.41) rotate(-45)"/>
      <rect class="cls-1" x="88.17" y="74.04" width="11.85" height="11.85" transform="translate(-7.49 150.17) rotate(-75)"/>
      <rect class="cls-1" x="66.42" y="109.55" width="14.8" height="14.8"/>
      <rect class="cls-1" x="51.55" y="137.02" width="9.74" height="9.74" transform="translate(-95.24 159.67) rotate(-75)"/>
      <rect class="cls-1" x="72.78" y="87.86" width="11.85" height="11.85" transform="translate(-21.59 23.57) rotate(-15)"/>
      <rect class="cls-1" x="29.27" y="88.03" width="14.8" height="14.8" transform="translate(-42.8 31.12) rotate(-30)"/>
      <rect class="cls-1" x="1.1" y="88.1" width="9.74" height="9.75" transform="translate(-23.86 4.71) rotate(-15)"/>
      <rect class="cls-1" x="53.13" y="82.22" width="11.85" height="11.85" transform="translate(-45.03 67.58) rotate(-45)"/>
      <rect class="cls-1" x="29.27" y="45.15" width="14.8" height="14.8" transform="translate(-27.18 58.04) rotate(-60)"/>
      <rect class="cls-1" x="18.48" y="19.92" width="9.74" height="9.74"
            transform="translate(-10.69 23.78) rotate(-45.01)"/>
      <rect class="cls-1" x="47.55" y="62.23" width="11.85" height="11.85"
            transform="translate(-26.19 102.17) rotate(-75)"/>
    </svg>

    <svg class="shadow text" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 146.85 16.11">
      <defs>
        <style>.cls-1 {
          fill: #fff;
        }</style>
      </defs>
      <path class="cls-1"
            d="m0,16.11V0h1.92l.51,2.06C4.07.69,5.88,0,7.86,0c4.02,0,6.03,2,6.03,6v10.11h-2.84V5.95c0-2.39-1.19-3.59-3.58-3.59-1.63,0-3.17.68-4.63,2.04v11.71H0Z"/>
      <path class="cls-1"
            d="m17.72,8.06c0-5.37,2.56-8.06,7.67-8.06s7.67,2.69,7.67,8.06-2.56,8.04-7.67,8.04-7.65-2.68-7.67-8.04Zm7.67,5.75c3.22,0,4.83-1.94,4.83-5.82s-1.61-5.69-4.83-5.69-4.83,1.9-4.83,5.69,1.61,5.82,4.83,5.82Z"/>
      <path class="cls-1" d="m34.6,0h3.07l4.62,12.63L46.88,0h3.07l-6.55,16.11h-2.64L34.6,0Z"/>
      <path class="cls-1"
            d="m51.48,11.25c0-3.3,2.18-4.96,6.54-4.96,1.4,0,2.8.1,4.2.31v-1.52c0-1.85-1.45-2.78-4.34-2.78-1.66,0-3.36.26-5.09.77V.77c1.74-.51,3.44-.77,5.09-.77,4.79,0,7.18,1.67,7.18,5v11.11h-1.6l-.98-1.6c-1.61,1.06-3.36,1.6-5.25,1.6-3.84,0-5.75-1.62-5.75-4.86Zm6.54-2.65c-2.47,0-3.7.87-3.7,2.61s.97,2.61,2.92,2.61,3.63-.52,4.99-1.57v-3.35c-1.4-.2-2.8-.31-4.2-.31Z"/>
      <path class="cls-1"
            d="m81.79,15.65c-1.33.31-2.76.46-4.3.46-5.73,0-8.59-2.79-8.59-8.36,0-5.17,2.86-7.75,8.59-7.75,1.53,0,2.97.15,4.3.46v2.3c-1.33-.31-2.81-.46-4.45-.46-3.73,0-5.6,1.82-5.6,5.45,0,4.04,1.87,6.06,5.6,6.06,1.64,0,3.12-.15,4.45-.46v2.3Z"/>
      <path class="cls-1"
            d="m97.52,15.65c-1.33.31-2.76.46-4.3.46-5.73,0-8.59-2.79-8.59-8.36,0-5.17,2.86-7.75,8.59-7.75,1.53,0,2.97.15,4.3.46v2.3c-1.33-.31-2.81-.46-4.45-.46-3.73,0-5.6,1.82-5.6,5.45,0,4.04,1.87,6.06,5.6,6.06,1.64,0,3.12-.15,4.45-.46v2.3Z"/>
      <path class="cls-1"
            d="m107.77,0c4.58,0,6.87,2.27,6.87,6.8,0,.64-.05,1.33-.14,2.07h-11.31c0,3.29,1.92,4.94,5.77,4.94,1.83,0,3.46-.2,4.88-.61v2.3c-1.42.41-3.15.61-5.19.61-5.53,0-8.3-2.75-8.3-8.24s2.47-7.87,7.41-7.87Zm-4.57,6.51h8.72c-.06-2.82-1.44-4.24-4.14-4.24-2.86,0-4.39,1.41-4.57,4.24Z"/>
      <path class="cls-1"
            d="m118.31,15.34v-2.46c1.89.61,3.81.92,5.74.92,2.57,0,3.85-.77,3.85-2.3,0-1.43-.9-2.15-2.69-2.15h-2.3c-3.48,0-5.22-1.59-5.22-4.76s2.24-4.6,6.71-4.6c1.92,0,3.83.26,5.72.77v2.46c-1.89-.61-3.8-.92-5.72-.92-2.68,0-4.02.77-4.02,2.3,0,1.43.84,2.15,2.53,2.15h2.3c3.68,0,5.52,1.59,5.52,4.76s-2.23,4.6-6.69,4.6c-1.93,0-3.85-.26-5.74-.77Z"/>
      <path class="cls-1"
            d="m134.42,15.34v-2.46c1.89.61,3.81.92,5.74.92,2.57,0,3.85-.77,3.85-2.3,0-1.43-.9-2.15-2.69-2.15h-2.3c-3.48,0-5.22-1.59-5.22-4.76s2.24-4.6,6.71-4.6c1.92,0,3.83.26,5.72.77v2.46c-1.89-.61-3.8-.92-5.72-.92-2.68,0-4.02.77-4.02,2.3,0,1.43.84,2.15,2.53,2.15h2.3c3.68,0,5.52,1.59,5.52,4.76s-2.23,4.6-6.69,4.6c-1.93,0-3.85-.26-5.74-.77Z"/>
    </svg>

  </div>
  <div class="language-dropdown" ngbDropdown>
    <button class="btn" type="button" ngbDropdownToggle>
      {{translation.getCurrentLanguage().code | uppercase}}
    </button>
    <div ngbDropdownMenu>
      <button ngbDropdownItem *ngFor="let language of translation.availableLanguages"
         (click)="translation.use(language)">
        {{language.code | uppercase}}
      </button>
    </div>
  </div>
</div>
