import {Injectable} from '@angular/core';

@Injectable()
export class StorageService {
  public setItem(key: string, value: any, isObject: boolean = false): void {
    const val = isObject ? JSON.stringify(value) : value;
    window.localStorage.setItem(key, val);
  }

  public getItem(key: string, isObject: boolean = false): any {
    const item = window.localStorage.getItem(key);

    if (isObject && item) {
      return JSON.parse(item);
    }

    return item;
  }

  public deleteItem(key: string): void {
    window.localStorage.removeItem(key);
  }
}
