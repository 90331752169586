import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {StorageService} from './storage.service';
import {IAvailableLanguages, settingsLanguageKey} from '../../constants';

@Injectable()
export class TranslationService {
  public readonly availableLanguages: Array<IAvailableLanguages> = [
    {code: 'fr', name: 'Français'},
    {code: 'en', name: 'English'},
    {code: 'de', name: 'Deutsch'}
  ];

  constructor(
    private translateService: TranslateService,
    private storageService: StorageService
  ) {
  }

  public initialize(): any {
    let languageName: IAvailableLanguages | undefined = this.getCurrentLanguage();

    if (!languageName) {
      const languageCode = navigator.language.substring(0, 2).toLowerCase();
      languageName = this.availableLanguages.find((language) => language.code === languageCode);
    }

    if (languageName) {
      this.use(languageName);
    } else {
      this.use({code: 'en', name: 'English'});
    }
  }

  public use(language: IAvailableLanguages): Observable<any> {
    this.storageService.setItem(settingsLanguageKey, language, true);
    moment.locale(language.code);
    return this.translateService.use(language.code);
  }

  public getCurrentLanguage(): IAvailableLanguages {
    const currentLang = this.storageService.getItem(settingsLanguageKey);

    if (currentLang) {
      return JSON.parse(currentLang);
    } else {
      const lang = this.availableLanguages.find((l: IAvailableLanguages) => l.code === this.translateService.defaultLang);

      if (!lang) {
        // tslint:disable-next-line:no-non-null-assertion
        return this.availableLanguages.find((l: IAvailableLanguages) => l.code === 'en')!;
      } else {
        return lang;
      }
    }
  }
}
