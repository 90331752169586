import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  constructor() {
  }

  public buttons = [
    {
      id: 'streetlighting',
      name: 'COMMONS.STREET_LIGHTING',
      darkColor: '#f48e2c',
      lightColor: '#f8d87a'
    },
    {
      id: 'mobility',
      name: 'COMMONS.MOBILITY',
      darkColor: '#5b727d',
      lightColor: '#a3b2ba'
    },
    {
      id: 'buildingsafety',
      name: 'COMMONS.BUILDING_SAFETY',
      darkColor: '#8f161c',
      lightColor: '#c54a39'
    },
    {
      id: 'map',
      name: 'COMMONS.MAP',
      darkColor: '#046cb8',
      lightColor: '#26c1e4'
    },
  ];

  private static openUrl(url: string): void {
    window.open(url);
  }

  ngOnInit(): void {
  }

  public navigateTo(type: string): void {
    switch (type) {
      case 'streetlighting':
        HomePageComponent.openUrl('https://novalight-demo1.novaccess.cloud/');
        break;
      case 'mobility':
        HomePageComponent.openUrl('https://versoix.novaccess.cloud/');
        break;
      case 'buildingsafety':
        HomePageComponent.openUrl('https://novasafety-demo1.novaccess.cloud/');
        break;
      case 'map':
        HomePageComponent.openUrl('https://earth.google.com/web');
        break;
    }
  }
}
