import {Component} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {TranslationService} from './services/translation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(private titleService: Title,
              private translationService: TranslationService) {
    this.titleService.setTitle('Novalight showroom');
    this.translationService.initialize();
  }
}
