<div class="wrapper">
 <div class="content">
   <p class="title">{{('COMMONS.TITLE' | translate).toUpperCase()}}</p>
   <span class="subtitle">{{'COMMONS.SUBTITLE' | translate}}</span>
     <div class="home-navigation-wrapper">
       <div *ngFor="let button of buttons" class="button-wrapper">
         <button type="button" class="btn" [ngStyle]="{background: 'linear-gradient(45deg, ' + button.darkColor + ',' +
          button.lightColor + ')'}"
                 (click)="navigateTo(button.id)">
           <img [src]="'app/assets/icons/' + button.id + '.png'" alt="street lighting" />
         </button>
         <span class="btn-label">{{button.name | translate}}</span>
       </div>
     </div>
 </div>
  <app-footer></app-footer>
</div>
